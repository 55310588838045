// import InputCounter from './components/input-counter.js';

// //
// // Input Counter
// //
// new InputCounter('.js-input-counter');

document.addEventListener('DOMContentLoaded', () => {
    document.documentElement.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
});